import axios from 'axios';

export const getCoeDate = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_COE_DATE_API}`);
    const { coeExpDate } = response.data;
    return coeExpDate;
  } catch (err) {
    console.error(err);
  }
};
